import React, { useEffect, useLayoutEffect } from 'react'
import { graphql } from 'gatsby'
import { useLocation } from '@reach/router'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import { makeStyles } from '@material-ui/core/styles'
import { Button, IconButton, Typography, useMediaQuery } from '@material-ui/core'
import activityTheme from '../styles/activityTheme'
import cisLogo from '../assets/CIS-logo.svg'
import printIcon from '../assets/print.svg'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from '@material-ui/core/styles'
import '../assets/activity.print.css'
import clsx from 'clsx'
import Content from '../components/Content'
import { Previewer } from 'pagedjs'
import { convertLineBreakToBr } from '../lib/helpers'
import baseTheme from '../styles/theme'

export const query = graphql`
  query ActivityTemplateQuery($id: String!) {
    activity: sanityActivity(id: { eq: $id }) {
      id
      _id
      title
      slug {
        current
      }
      activityCategory
      mainImage {
        caption
        colorImage {
          asset {
            url
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      content {
        ... on SanityActivityBlockContentObject {
          _key
          _type
          _rawContent(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityFullWidthImage {
          _key
          _type
          asset {
            id
            url
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
        ... on SanityImageWithCaption {
          _key
          _type
          caption
          alt
          asset {
            url
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
        ... on SanityPageBreak {
          _key
          _type
          enabled
        }
        ... on SanityTwoColumnText {
          _key
          _type
          _rawCol1
          _rawCol2
        }
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`
const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 720,
    margin: '0 auto',
    background: activityTheme.custom.colors.beige,
    [theme.breakpoints.down('xs')]: {
      margin: '0 20px'
    }
  },
  toolbar: {
    borderBottom: '1px solid #707070',
    padding: '22px 0',
    marginBottom: 30,
    textAlign: 'right'
  },
  printButton: {
    padding: 0,
    '& img': {
      width: 45,
      height: 45
    }
  },
  activitiesButton: {
    marginLeft: 16,
    fontSize: 12,
    marginTop: 0,
    marginBottom: 0
  },
  mainImage: {
    width: '100%'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    borderBottom: `1px solid #707070`,
    paddingBottom: 30
  },
  title: {
    margin: 0,
    fontSize: 55,
    '@media print': {
      fontSize: '32pt'
    }
  },
  logo: {
    height: '32pt',
    flexShrink: 0,
    position: 'relative',
    top: '2pt',
    '@media print': {
      height: '32pt'
    }
  },
  logoPrint: {
    height: '32pt'
  },
  contentContainer: {
    marginTop: '2em',
    paddingBottom: '7em'
  },
  twoCol: {
    display: 'flex',
    gap: '40px',
    '&>.col': {
      flexBasis: '100%',
      flex: 1
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  mainSubtitle: {
    color: '#0060C7'
  }
}))

const getStyles = (doc = document) => {
  // Get all stylesheets
  let stylesheets = Array.from(doc.querySelectorAll("link[rel='stylesheet']"))
  let hrefs = stylesheets.map(sheet => {
    return sheet.href
  })

  // Get inline styles
  let inlineStyles = Array.from(doc.querySelectorAll('style:not([data-pagedjs-inserted-styles])'))
  inlineStyles.forEach(inlineStyle => {
    let obj = {}
    obj[window.location.href] = inlineStyle.textContent
    hrefs.push(obj)
  })

  return hrefs
}

const ActivityTemplate = props => {
  const { data, errors } = props
  const activity = data && data.activity
  const classes = useStyles()
  const location = useLocation()
  const { search: query } = location
  const urlParams = new URLSearchParams(query)
  const showPrintPreview = urlParams.get('printPreview') === 'true'
  const mediaSmall = useMediaQuery(baseTheme.breakpoints.down('sm'))

  useLayoutEffect(() => {
    const previewer = new Previewer()
    document.querySelector('#activity-print-preview').innerHTML = ''
    // console.log('HTML to be processed:', document.querySelector('#activity-root').innerHTML)
    previewer
      .preview(
        document.querySelector('#activity-root').innerHTML,
        getStyles(),
        document.querySelector('#activity-print-preview')
      )
      .then(flow => {
        if (showPrintPreview) {
          const rootClassList = document.querySelector('#activity-root').classList
          const previewClassList = document.querySelector('#activity-print-preview').classList
          rootClassList.add('hide')
          rootClassList.remove('show')
          previewClassList.add('show')
          previewClassList.remove('hide')
          document.documentElement.classList.add('printPreview')
          document.body.style.setProperty('background', 'none')
        }
        // console.log('preview rendered, total pages', flow.total, { flow })
      })
    return () => {
      document.head
        .querySelectorAll('[data-pagedjs-inserted-styles]')
        .forEach(e => e.parentNode?.removeChild(e))
    }
  }, [activity, showPrintPreview])

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--activityTitle',
      `'Partners In Healing: ${activity.title}'`
    )
  }, [activity])

  const handlePrint = () => {
    window.print()
  }

  return (
    <ThemeProvider theme={activityTheme}>
      <div id="activity-root" className={clsx(classes.root, showPrintPreview && 'printPreview')}>
        {errors && <SEO title="GraphQL Error" />}
        {activity && <SEO title={activity.title || 'Untitled'} />}
        {errors && <GraphQLErrorList errors={errors} />}

        <Helmet>
          {showPrintPreview && (
            <style>
              {`
              body {
                background: white;
              }
              `}
            </style>
          )}
        </Helmet>

        {!showPrintPreview && (
          <div id="activity-toolbar" className={classes.toolbar}>
            <IconButton onClick={handlePrint} className={classes.printButton}>
              <img src={printIcon} />
            </IconButton>
            <Button href="/activities" className={classes.activitiesButton}>
              More Activities
            </Button>
          </div>
        )}

        <div className={classes.titleContainer}>
          <Typography variant="h1" className={clsx(classes.title)}>
            {activity.title}
          </Typography>
          {!mediaSmall && (
            <img
              className={clsx(classes.logo, showPrintPreview && classes.logoPrint)}
              src={cisLogo}
            />
          )}
        </div>

        <div className={classes.contentContainer}>
          <div className={clsx(classes.twoCol, classes.mainImageContainer)}>
            <div className={clsx('col')}>
              {activity.mainImage && activity.mainImage.colorImage && (
                <img src={activity.mainImage.colorImage.asset.url} className={classes.mainImage} />
              )}
            </div>
            <div className={clsx('col')}>
              <Typography variant="subtitle1" className={classes.mainSubtitle}>
                {activity.mainImage && convertLineBreakToBr(activity.mainImage.caption)}
              </Typography>
            </div>
          </div>
          {activity.content && <Content blocks={activity.content || []} />}
        </div>
      </div>
      <div id="activity-print-preview"></div>
    </ThemeProvider>
  )
}

export default ActivityTemplate
