import { createMuiTheme } from '@material-ui/core/styles'
import baseTheme from './theme'

const activityTheme = createMuiTheme({
  ...baseTheme,
  typography: {
    h1: {
      ...baseTheme.typography.h1,
      marginBottom: '.25em',
      fontSize: '32pt'
    },
    h2: {
      ...baseTheme.typography.h2,
      marginBottom: '.625em'
    },
    h3: {
      ...baseTheme.typography.h3
    },
    body1: {
      ...baseTheme.typography.body1,
      minHeight: '1em',
      marginBottom: '1em'
    },
    subtitle1: {
      ...baseTheme.typography.subtitle1,
      minHeight: '1em',
      fontSize: '16pt',
      marginBottom: '.75em',
      [baseTheme.breakpoints.down('sm')]: {
        fontSize: '16pt'
      }
    }
  }
})

export default activityTheme
